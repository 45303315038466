import React, { Component } from 'react'
import { useNavigate, Link, json } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Modal, Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form } from 'react-bootstrap';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartSimple } from '@fortawesome/free-solid-svg-icons';
import { faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faWallet } from '@fortawesome/free-solid-svg-icons';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { GetForexPageload } from "../../Services/ForexServices";

class Forex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CityList: [],
      activeKey: 'buy',
      showCardModal: false,
      showEnqdModal: false,
    };
  }

  componentDidMount() {
    this.getForexDetails();
  }

  getForexDetails() {
    GetForexPageload().then((response) => {
      this.setState({
        CityList: response.data.cityList,
      });
    });
  }

  handleSelect = (key) => {
    this.setState({ activeKey: key });
  };
  handleCardShow = () => {
    this.setState({ showCardModal: true });
  };

  handleCardClose = () => {
    this.setState({ showCardModal: false });
  };


  handleEnqShow = () => {
    this.setState({ showEnqdModal: true });
  };

  handleEnqClose = () => {
    this.setState({ showEnqdModal: false });
  };
  render() {
    const { CityList } = this.state;
    return (
      <div>
        {/* <section className="page-header-forex" style={{ backgroundImage: 'url("assets/img/forex-banner.jpg")' }}>
          <div className="forex-enquiry position-relative">
            <Container>
              <Row className="align-items-center justify-content-center">
                <Col lg={7} md={7}>
                  <div className="forex-tab">
                    <div className="parent_div"></div>
                    <div className="inner_form">
                      <Tabs
                        activeKey={this.state.activeKey}
                        onSelect={this.handleSelect}
                        transition={false}
                        id="forex-enq-tab"
                        className="mb-3"
                      >
                        <Tab eventKey="buy" title="Buy Forex">
                          <div className="forex-form">
                            <div className="position-relative forex-input">
                              <select className="form-control">
                                {CityList.length > 0 && CityList.map((item, index) => (
                                  <option key={index} value={item.id}>{item.city}</option>
                                ))}
                              </select>
                              <FontAwesomeIcon icon={faLocationDot} className="forex-loc" />
                            </div>

                            <Row>
                              
                              <Col lg={12} md={12} className="forex-input2">
                                <label className="forex-label">Currency You want</label>
                                <select className="form-control">
                                  <option>UAE Dirham</option>
                                </select>
                              </Col>


                            </Row>
                            <div className="position-relative pt-3 forex-input">
                              <input type="text" className="form-control" placeholder="Forex Amount" />
                              <span className="currency_rate">
                                <FontAwesomeIcon icon={faChartSimple} /> Rate = 84.32
                              </span>
                              <FontAwesomeIcon icon={faMoneyBillWave} className="forex-amount" />
                            </div>

                            <div className="position-relative pt-3 forex-input">
                              <FontAwesomeIcon icon={faMoneyBillWave} className="forex-amount" />
                              <input type="text" className="form-control" placeholder="INR Amount" />

                            </div>
                            <div className="total-amount-block pt-2">
                              <h6>Total Amount</h6>
                              <div className="d-flex flex-row align-items-center">
                                <div className="total-amount-block mr-10">
                                  <FontAwesomeIcon icon={faWallet} className="t-cart" />
                                </div>
                                <div>
                                  <h4>0.00</h4>
                                </div>

                              </div>
                            </div>
                            <div className="text-center forex-btn">
                              <button type="button" className="btn btn-doc" onClick={this.handleEnqShow}>Buy Forex</button>
                            </div>
                          </div>

                          <Modal
                            show={this.state.showEnqdModal}
                            onHide={this.handleEnqClose}

                          >
                            <Modal.Header closeButton>
                              <Modal.Title id="full-screen-modal">
                                Buy Forex
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="enquiry-form">
                                <div className="pt-2 pb-2">
                                  <input type="text" className="form-control" placeholder="Full Name" />
                                </div>
                                <div className="pt-2 pb-2">
                                  <input type="text" className="form-control" placeholder="Mobile No." />
                                </div>
                                <div className="pt-2 pb-2">
                                  <input type="text" className="form-control" placeholder="Email" />
                                </div>
                                <div className="pt-2 pb-2">
                                  <button className="btn btn-doc w-100">Submit</button>
                                </div>
                              </div>
                            </Modal.Body>

                          </Modal>





                        </Tab>
                        <Tab eventKey="sell" title="Sell Forex">
                          <div className="forex-form">
                            <div className="position-relative forex-input">
                              <select className="form-control">
                                <option>Select City</option>
                                <option>Kozhikode</option>
                              </select>
                              <FontAwesomeIcon icon={faLocationDot} className="forex-loc" />
                            </div>


                            <div className="forex-input2">
                              <label className="forex-label">Choose Currency</label>
                              <select className="form-control">
                                <option>US Dollar</option>
                              </select>
                            </div>




                            <div className="position-relative pt-3 forex-input">
                              <input type="text" className="form-control" placeholder="Enter Quantity" />
                              <span className="currency_rate">
                                <FontAwesomeIcon icon={faChartSimple} /> Rate = 84.32
                              </span>
                              <FontAwesomeIcon icon={faMoneyBillWave} className="forex-amount" />
                            </div>


                            <div className="total-amount-block pt-2">
                              <h6>Total Amount(INR)</h6>
                              <div className="d-flex flex-row align-items-center">
                                <div className="total-amount-block mr-10">
                                  <FontAwesomeIcon icon={faWallet} className="t-cart" />
                                </div>
                                <div>
                                  <h4>0.00</h4>
                                </div>

                              </div>
                            </div>
                            <div className="text-center forex-btn">
                              <button type="button" className="btn btn-doc">Sell Forex</button>
                            </div>
                          </div>
                        </Tab>

                      </Tabs>
                    </div>

                  </div>

                </Col>

                <Col lg={5} md={5}>
                  <OwlCarousel
                    items={1}
                    className="owl-carousel"
                    loop
                    autoplay
                    margin={8}
                    responsive={{
                      0: {
                        items: 1,
                      },
                      600: {
                        items: 1,
                      },
                      1000: {
                        items: 1,
                      },
                    }}
                  >
                    <div className="item forex-area">

                      <div className="text-center">
                        <h4 className="text-white">Buy & Sell Foreign Currency Notes at Best Rates with Alhind</h4>
                        <p>Guaranteed Best Rates in Market</p>

                      </div>

                    </div>
                    <div className="item forex-area text-center">

                      <h4 className="text-white">Genuine currency notes from verified RBI-licensed companies</h4>
                      <p>Guaranteed Best Rates in Market</p>
                    </div>
                    <div className="item forex-area text-center">

                      <h4 className="text-white">Best rates compared across hundreds of money changers</h4>
                      <p>Guaranteed Best Rates in Market</p>
                    </div>







                  </OwlCarousel>
                </Col>


              </Row>
            </Container>
          </div>
        </section>

        <section className="rate-card pt-5 pb-5">
          <Container>

            <OwlCarousel
              items={5}
              className="owl-carousel"

              autoplay
              margin={10}
              responsive={{
                0: {
                  items: 2,
                },
                600: {
                  items: 2,
                },
                1000: {
                  items: 5,
                },
              }}
            >
              <div className="card-basic">
                <div className="card-header header-basic">
                  <h4>USD<br />US DOLLAR</h4>
                </div>
                <div className="card-body">
                  <table className="table">
                    <tr>
                      <td className="buy-txt">Buy</td>
                      <td className="buy-txt">23.46</td>
                    </tr>
                    <tr>
                      <td className="buy-txt">Sell</td>
                      <td className="buy-txt">23.46</td>
                    </tr>

                  </table>

                </div>
              </div>
              <div className="card-standard">
                <div className="card-header header-standard">
                  <h4>AED<br />UAE Dirham</h4>
                </div>
                <div className="card-body">
                  <table className="table">
                    <tr>
                      <td className="buy-txt">Buy</td>
                      <td className="buy-txt">23.46</td>
                    </tr>
                    <tr>
                      <td className="buy-txt">Sell</td>
                      <td className="buy-txt">23.46</td>
                    </tr>

                  </table>

                </div>
              </div>
              <div className="card-premium">
                <div className="card-header header-premium">
                  <h4>GBP<br />Sterling Pound</h4>
                </div>
                <div className="card-body">
                  <table className="table">
                    <tr>
                      <td className="buy-txt">Buy</td>
                      <td className="buy-txt">23.46</td>
                    </tr>
                    <tr>
                      <td className="buy-txt">Sell</td>
                      <td className="buy-txt">23.46</td>
                    </tr>

                  </table>
                </div>
              </div>
              <div className="card-premium">
                <div className="card-header header-premium">
                  <h4>GBP<br />Sterling Pound</h4>
                </div>
                <div className="card-body">
                  <table className="table">
                    <tr>
                      <td className="buy-txt">Buy</td>
                      <td className="buy-txt">23.46</td>
                    </tr>
                    <tr>
                      <td className="buy-txt">Sell</td>
                      <td className="buy-txt">23.46</td>
                    </tr>

                  </table>
                </div>
              </div>
              <div className="card-premium">
                <div className="card-header header-premium">
                  <h4>GBP<br />Sterling Pound</h4>
                </div>
                <div className="card-body">
                  <table className="table">
                    <tr>
                      <td className="buy-txt">Buy</td>
                      <td className="buy-txt">23.46</td>
                    </tr>
                    <tr>
                      <td className="buy-txt">Sell</td>
                      <td className="buy-txt">23.46</td>
                    </tr>

                  </table>
                </div>
              </div>
              <div className="card-premium">
                <div className="card-header header-premium">
                  <h4>GBP<br />Sterling Pound</h4>
                </div>
                <div className="card-body">
                  <table className="table">
                    <tr>
                      <td className="buy-txt">Buy</td>
                      <td className="buy-txt">23.46</td>
                    </tr>
                    <tr>
                      <td className="buy-txt">Sell</td>
                      <td className="buy-txt">23.46</td>
                    </tr>

                  </table>
                </div>
              </div>


            </OwlCarousel>

            <div className="text-center pt-2">
              <button className="btn btn-doc" onClick={this.handleCardShow}> <FontAwesomeIcon icon={faChartBar} /> View Full Rate Card</button>
            </div>

            <Modal
              show={this.state.showCardModal}
              onHide={this.handleCardClose}
              dialogClassName="modal-100w"
              aria-labelledby="full-screen-modal"
              fullscreen={true}
            >
              <Modal.Header closeButton>
                <Modal.Title id="full-screen-modal">
                  Forex Rate Card
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Currency</th>
                      <th>Buy Rate</th>
                      <th>Sell Rate</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>UAE Dirham</td>
                      <td>23.30</td>
                      <td>22.80</td>

                    </tr>

                  </tbody>
                </Table>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={this.handleCardClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>














            <main className="d-flex flex-row">

            </main>

          </Container>
        </section>

        <section className="forex-about pt-5 pb-5">
          <Container>
            <div className="section-title text-center">
              <h2>About Alhind Forex</h2>
            </div>
            <p className="text-center">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
            <div className="success-story bg-white">
              <Row>
                <Col lg={3} md={3} className="text-center">
                  <div className="forex-about-icon">
                    <img src="assets/img/office.png" width={80} />
                  </div>
                  <h3>4500+</h3>
                  <p>Branches all over india</p>
                </Col>

                <Col lg={3} md={3} className="text-center">
                  <div className="forex-about-icon">
                    <img src="assets/img/customers.png" width={80} />
                  </div>
                  <h3>2500000+</h3>
                  <p>Satisfied Customers</p>
                </Col>
                <Col lg={3} md={3} className="text-center">
                  <div className="forex-about-icon">
                    <img src="assets/img/legacy.png" width={80} />
                  </div>
                  <h3>30+</h3>
                  <p>Years of Legacy</p>
                </Col>
                <Col lg={3} md={3} className="text-center">
                  <div className="forex-about-icon">
                    <img src="assets/img/money.png" width={80} />
                  </div>
                  <h3>100%</h3>
                  <p>Your Money is Safe</p>
                </Col>
              </Row>
            </div>
          </Container>
        </section>

<section className="forex-adv pt-5 pb-5">
<Container>
  <Row>
    
  </Row>
</Container>
</section> */}





        {/* Coming soon section starts */}
     <div className="container">
        <h2 class="mb-2 mx-2 cs-title mt-5 text-center">Launching soon!</h2>
        <p className="cs-text text-center">We are currently developing the system for a better experience.<br/>Apologize for the inconvenience and thank you.</p>
        <div className="row align-items-center justify-content-center">
        <div className="col-lg-4 col-md-4">
       
        <img src="assets/img/forex-bg.webp" className="img-fluid" />
        </div>
        <div className="col-lg-6 col-md-6">
        <div className="card cs-card">
          <div className="card-title">
          <h4>For Forex Enquiries Please contact us </h4>
          </div>
          <div className="card-body">
         
          <h4><img src="assets/img/support.svg" width={30}/> 0495 2389900</h4>
        <h4><img src="assets/img/email.svg" width={30}/> b2c@alhindonline.com</h4>
          </div>
        </div>
        </div>
        </div>

        </div> 

        {/* Coming soon ends */}



      </div>
    )
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <Forex {...props} navigate={navigate} />;
}

export default WithNavigate;
