import React, { Component } from 'react'
import { useNavigate, Link, json } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { TopVisaList } from '../../Services/VisaService';
import Autocomplete from 'react-autocomplete';

class Visa extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      TopVisaList: [],
      AllCountries: [],
    };
  }


  componentDidMount() {
    this.GetAllCountries({ Keyword: "" });
  }

  GetAllCountries(data) {
    TopVisaList(data).then((response) => {
      if (response.data.status) {
        this.setState({
          AllCountries: response.data.topVisaList,
        });
      }
    });
  }

  onChange = (event) => {
    const value = event.target.value;
    this.setState({ value });

    let data = {};
    data.keyword = value;
    this.getTopVisaList(data);
  };

  getTopVisaList(data) {
    TopVisaList(data).then((response) => {
      if (response.data.status) {
        this.setState({
          TopVisaList: response.data.topVisaList,
        });
      }
    });
  }

  gotoDetailsPage = (id) => {
    this.props.navigate(`/VisaDetails/${id}`);
  };

  render() {
    const { value, TopVisaList, AllCountries } = this.state;
    const filteredItems = value.length > 0 ? TopVisaList.slice(0, 5) : [];

    return (
      <div>
        {/* <section className="page-header">
          <div
            className="page-header__bg"
            style={{ backgroundImage: 'url("assets/img/visa-banner.jpg")' }}
          />
          <div className="container">
            <h2 className="page-header__title">Getting Visa is Simple with alhind!</h2>
            <p className="text-white">Search and Select Country</p>
            <div className="wrapper">
              <div className="search-input">

                <Autocomplete
                  getItemValue={(item) => String(item.countryId)}
                  items={filteredItems}
                  renderItem={(item, isHighlighted) => {
                    return (
                      <div style={{ background: isHighlighted ? 'lightgray' : 'white', cursor: 'pointer', textAlign: 'left', paddingLeft: '10px', fontSize: '17px' }}>
                        {item.countryName}
                      </div>
                    );
                  }}
                  value={value}
                  onChange={this.onChange}
                  onSelect={(val) => this.gotoDetailsPage(val)}
                />
   

                <div className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                  </svg>
                </div>
              </div>

            </div>

          </div>
        </section>
        {AllCountries.length > 0 && <section className="pt-50">
          <div className="container">
            <div className="pt-20 pb-20">
              <p className="text-center">Since a visa is the sole document that has the power to make or break your travel arrangements, it is the most important document for each traveler. Don't worry, though; our dedicated Visa department provides expert and value-added Visa services that guarantee your Visa will arrive on time.</p>
            </div>

            <h2 className="text-center">Popular Visa Assistance Destinations</h2>
            <OwlCarousel
              items={5}
              className="owl-carousel top-flight-deals visa"

              autoplay

              margin={8}
              responsive={{
                0: {
                  items: 1,
                },
                600: {
                  items: 3,
                },
                1000: {
                  items: 5,
                },
              }}
            >


              {AllCountries.length > 0 && AllCountries.map((item, index) => (

                <div className="visa-route" onClick={() => this.gotoDetailsPage(item.countryId)}>
                  <div className="visa-offer">
                    <h5>{item.countryName}</h5>
                  </div>
                  <div className="visa-dest-img">
                    {item.imageLocation ? (
                      <img src={item.imageLocation} className="img-fluid" alt="Item Image" />
                    ) : (
                      <img src="/assets/img/visa_default_thumb.jpg" className="img-fluid" alt="Default Image" />
                    )}

                  </div>
                  <div className="visa-price">
                    {(item.amount > 0) ? <h5 className="no-margin"><span className="v-small">Starting at </span>&#8377;{item.amount}</h5>
                      : <h5 className="no-margin">Contact us</h5>
                    }
                  </div>
                </div>


              ))}

            </OwlCarousel>

          </div>
        </section>}

        <section className="pt-50 why-choose">
          <h2 className="text-center">Why choose us?</h2>
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4 text-center">
                <div className="card text-center">
                  <img src="assets/img/visa-icons/visa-service.png" width={80} />
                  <h6>Visa Services for all Countries</h6>
                </div>

              </div>

              <div className="col-lg-4 col-md-4 text-center">
                <div className="card text-center">
                  <img src="assets/img/visa-icons/visa-processing.png" width={80} />
                  <h6>25 years of experience<br />
                    in Visa processing</h6>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 text-center">
                <div className="card text-center">
                  <img src="assets/img/visa-icons/worldwide.png" width={80} />
                  <h6>150+ Branches<br />
                    Worldwide</h6>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 text-center">
                <div className="card text-center">
                  <img src="assets/img/visa-icons/customer-service.png" width={80} />
                  <h6>End-to-End Visa<br />Assistance</h6>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 text-center">
                <div className="card text-center">
                  <img src="assets/img/visa-icons/delivery.png" width={80} />
                  <h6>Pick Up & Drop of<br />
                    Documents from your Doorstep</h6>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 text-center">
                <div className="card text-center">
                  <img src="assets/img/visa-icons/safety.png" width={80} />
                  <h6>Safety & Confidentiality</h6>
                </div>
              </div>

            </div>
          </div>
        </section>

        <section className="pt-50" style={{ backgroundColor: '#0079ab' }}>
          <div className="container">
            <h2 className="text-center text-white">See Our Visa processing</h2>
            <div className="visa-timeline">
              <div className="row">
                <div className="col-lg-4 col-md-4 text-center">
                  <div className="icon-bg">
                    <img src="assets/img/visa-icons/docs-submit.png" width={40} />
                  </div>

                  <h4 className="text-white">Submit documents</h4>
                </div>

                <div className="col-lg-4 col-md-4 text-center">
                  <div className="icon-bg">
                    <img src="assets/img/visa-icons/docs-verify.png" width={40} />
                  </div>
                  <h4 className="text-white">We verify documents<br />
                    & process your Visa application</h4>
                </div>

                <div className="col-lg-4 col-md-4 text-center">
                  <div className="icon-bg">
                    <img src="assets/img/visa-icons/recieve.png" width={40} />
                  </div>
                  <h4 className="text-white">Receive Visa</h4>
                </div>

              </div>
            </div>



          </div>
        </section> */}




        {/* Visa coming soon area starts */}
      <div className="container">
        <h2 class="mb-2 mx-2 cs-title mt-5 text-center">Launching soon!</h2>
        <p className="cs-text text-center">We are currently developing the system for a better experience.<br/>Apologize for the inconvenience and thank you.</p>
        <div className="row align-items-center justify-content-center">
        <div className="col-lg-4 col-md-4">
       
        <img src="assets/img/visa-bg.webp" className="img-fluid" />
        </div>
        <div className="col-lg-6 col-md-6">
        <div className="card cs-card">
          <div className="card-title">
          <h4>For Visa Services and Enquiries Please contact us </h4>
          </div>
          <div className="card-body">
         
          <h4><img src="assets/img/support.svg" width={30}/> 0495 2389900</h4>
        <h4><img src="assets/img/email.svg" width={30}/> b2c@alhindonline.com</h4>
          </div>
        </div>
        </div>
        </div>

        </div> 

        {/* Visa coming soon area ends */}

      </div>
    )
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <Visa {...props} navigate={navigate} />;
}

export default WithNavigate;
