import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

function Footer(props) {
  const navigate = useNavigate();

  const PrivacyPolicy = () => {
    navigate('/Privacy_Policy');
  };

  const Disclaimer = () => {
    navigate('/Disclaimer');
  };
  const TermsofUse = () => {
    navigate('/TermsofUse');
  };
  

  return (
    <div>
       <div>
        {/* <div className="top-footer">
          <div className="container">
            <div className="d-flex">
              <div className="top-footer-links">
                <a >International Flight Tickets</a>
                <a >Domestic Flight Tickets</a>
              </div>
            </div>
          </div>
        </div> */}
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-12 footer-widget">
                <img src="/assets/img/logo-alhind.webp" alt=""/>
              <ul className="social">
<li><Link to="https://www.facebook.com/alhindgroup/" target="_blank"><span className="facebook"></span></Link></li>
<li><Link to="https://www.instagram.com/alhind_com/" target="_blank"><span className="insta"></span></Link></li>
<li><Link to="https://www.linkedin.com/company/alhindonline" target="_blank"><span className="linked"></span></Link></li>
<li><Link to="https://www.youtube.com/AlhindOnline" target="_blank"><span className="ytub"></span></Link></li>
<li><Link to="https://twitter.com/alhindonline" target="_blank"><span className="twitter"></span></Link></li>
              </ul>
              </div>
              <div className="col-lg-3 col-md-3 col-6 footer-widget">
                <h4>Useful Links</h4>
                <ul>
                      <li><Link to="https://alhindgroup.com/" target="_blank">About us</Link></li>
                <li>
                  <a onClick={Disclaimer}>Disclaimer</a>
                 
                  </li>
                  <li>
                  <a onClick={PrivacyPolicy}>Privacy Policy</a>
                 
                  </li>
                  <li>
                  <a onClick={TermsofUse}>Terms of Use</a>
                 
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-3 col-6 footer-widget">
                <h4>Services</h4>
                <ul className="footer-service">
                  <li><Link to="/">Flights</Link></li>
                  <li><Link to="http://www.alhindbus.com/" target="_blank">Holidays</Link></li>
                  <li><Link to="Hotel">Hotel</Link></li>
                  <li><Link to="Visa">Visa</Link></li>
                  <li><Link to="Forex">Forex</Link></li>
                  <li><Link to="Insurance">Insurance</Link></li>
                  <li><Link to="http://www.alhindbus.com/" target="_blank">Bus</Link></li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-3 col-12 footer-widget">
                <h4>Connect us</h4>
                <ul>
             <li>0495 2389900</li> 
                  <li>b2c@alhindonline.com</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="copyright">
            <div className="container">
            <div className="d-flex flex-row align-items-center">
  <div className="c-text">
    <p>Copyright © 2024, Alhind all rights reserved.</p>
  </div>
  <div className="flex-grow-1"></div> 
  <div className="payment">
 
    <img src="/assets/img/payments.png" />
  </div>
</div>

            
            </div>
          
          </div>
        </footer>
        <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Sign in to your Alhind Account!</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <form className="login-form" id="login" >
                  <input type="text" className="form-control" defaultValue="Enter Mobile Number" />
                  <button type="submit" className="btn btn-primary">GET OTP</button>
                  <div className="pt-2 pb-2">
                    <p className="or-section">Or</p>
                  </div>
                  <div className="text-center">
                    <a  className="google-login"><img src="/assets/img/Google__G__Logo.svg" width={30} alt=""/> Sign in with Google</a>
                  </div>
                  <div className="pt-3 pb-3 small-text text-center"><p>By continuing, you agree to our <a  className="privacy-link">privacy policy &amp; terms of use.</a></p></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
